
 .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

 .App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

 .App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


.MuiInputBase-input {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.MuiListItemIcon-root {
  min-width: 18px;
  padding-right:5px;
}

.helperHeaderText{
  color: LightSteelBlue;
  font-size: 10px;
  max-height: 14px;
  margin-top:0;
  padding:0;
}

.helperHeaderIcon{
  margin-right: 3px;
  font-size: 10px !important;
}

td {
  padding : 0;
  padding-bottom : 0;
  padding-left : 0;
  padding-right : 0;
  padding-top : 0;
}


 #mf.footer {
   position:fixed;
   left:0px;
   bottom:0px;
   height:30px;
   width:100%;
   background:#999;
}

 .tutorial-image{
    max-width:300px;
    width:100%;
    border: 1px solid black;
    border-radius: 3px;
}

 .feature-image{
    max-width:400px;
    max-height:400px;
    border: 1px solid black;
    border-radius: 3px;
}

 .tutorial-header {
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    padding-left: 10px;
}

table.mf-table td { 
    padding: 1px 1px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    /*border-radius: 2px;*/

    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    font-size:110%;
    padding-left: 40px;
}

table.mf-table th { 
    padding: 1px 1px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    font-size:140%;
}

table.pull td { 
    padding: 1px 1px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    border: 1px solid black;
}
table.pull th { 
    padding: 1px 1px;
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    background-color:rgb(189, 188, 188);
    border: 1px solid black;
}


 .mf-yes{
    background:url(/images/yes.png) no-repeat; background-position:center;
    width:35px;
    height:30px;
    
}
 .mf-no{
    background:url(/images/no.png) no-repeat; background-position:center;
}

 .mf-feat {
    width:30%;
}
 .mf-img{
    background:no-repeat; 
    background-position:top; 
    height:150px;
    background-size: 100%;
}


 .mf_floral_font
{
  font-family: 'Tangerine', cursive;
  font-size:150%;
}

 .mf_floral_font_header 
{
  font-family: 'Tangerine', cursive;
  font-size:300%;
  margin-top:5px;
  padding-top:10px;
  line-height: 1;
}

 .mf_floral_font_doubleHeaderSize {
  font-size:400%;
}

 .mf_floral_font_headerSize {
  font-size:300%;
}
 .mf_floral_font_mediumSize {
  font-size:125%;
}
 .mf_floral_font_textSize {
  font-size:125%;
}
 .mf_floral_font_smallSize {
  font-size:100%;
}


 .mf_floral_font_small 
{
  color:white;
  font-family: 'Tangerine', cursive;
  font-size:-50%;
  text-shadow: 1px 1px 1px black, 0 0 2px green;
}

 .mf_floral_font_small_black 
{
  color:black;
  font-family: 'Tangerine', cursive;
  font-size:-50%;
}

 .mf_floral_font_normal_black 
{
  color:black;
  font-family: 'Tangerine', cursive;  
}


 .mf_menu_font 
{
    font-family: 'Open Sans', cursive;
    font-weight: 500;
    font-color: black;
}

 .mf_discussion_font_tiny_button 
{
    font-family: 'Open Sans', cursive;
    font-weight: 500;
    font-color: black;    
}

 .mf_discussion_font_tiny_header 
{
    font-family: 'Open Sans', cursive;
    font-weight: 500;
    font-color: black;
    font-size:65%;
}

 .mf_subHeader
{
  color:black;
  font-family: 'Open Sans', cursive;  
  font-weight: 500;
  font-size:150%;
}

 .mf_subsubHeader
{
  color:black;
  font-family: 'Open Sans', cursive;  
  font-weight: 300;
  font-size:125%;
}

.mf_smallHeader
{
  color:black;
  font-family: 'Open Sans', cursive;  
  font-weight: 300;
  font-size:75%;
}


 .boldLine
{
    font-weight: bold;
}

 .mf_nav_back_color {
  /*background-color: #D7D5A7;*/
}

#galleria{height:320px;}

 .mf_rental_image {
    height:350px;
}

 .mf_rounded img{
    border-radius:5px;
}

 .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    
}

/*
 .embed-container( iframe,  .embed-container) object,  .embed-container) embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
*/

 .parallax-container {
  min-height: 380px;
  line-height: 0;
  height: auto;
  color: rgba(255,255,255,.9);
}

.parallax-container  .section {
width: 100%;
}

 .mqClr {
  background-color: navajowhite
}

 .gridButton {
  border: 1px solid #eee;
  margin: 1px 0;
  color: white;
  min-width: 150px;
}

 .smallButton {
  font-size: 75%;
  margin: 3px;
  color: white;
  max-width:45px;
}

 .fbLoginBtn {
  border: 1px solid #eee;
  font-size:150%;
  background-color:#26a69a;
  color:white;
  margin:3px;
}

 .wideGridButton {
  min-width:300px;  
}

 #toast-container {
  bottom: auto !important;
  left: auto !important;
  
  max-width: 450px;
  top: 5%;
  right:5%;  
}

.col {
  padding:0px !important;
  box-sizing:border-box;
}

 .react-grid-Canvas {
  overflow-x: hidden !important;
}

 .tableReadOnly {
  background-color: Tan ;
}

 .react-grid-Cell {
  border-right: 0px !important;
  border-left: 0px !important;
}


 .fixedCol {
  padding:0px;
  margin:0xp;
}

 * {
  min-height: 0;
  min-width: 0;
}

 .TabBorder {
  margin-top:0px;
  padding-top:0px;
}

 .ProperDatePicker {
   display: inline-block;
   margin: 0px !important;
   margin-right: 5px !important;
   padding: 2px !important;
   border: 1px solid silver  !important;
   border-collapse: 1px solid grey  !important;
   white-space:nowrap  !important;
   height:initial !important;
   width: 333px;
   min-width: 333px; 
   background-color: white;  
}

 .ProperDatePickerNarrow {
   display: inline-block;
   margin: 0px !important;
   margin-right: 5px !important;
   padding: 2px !important;
   border: 1px solid silver  !important;
   border-collapse: 1px solid grey  !important;
   white-space:nowrap  !important;
   height:initial !important;
   width: 145px  !important;
   min-width: 145px  !important;   
}

 .react-tabs__tab {
  padding:3px !important;
}

.react-tabs__tab-panel--selected {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}


 .testIdTab0{  
  white-space:nowrap
}

 .testIdTab1{  
  white-space:nowrap
}

 .testIdTab2{  
  white-space:nowrap
}


 .slick-slider,  .slick-list,  .slick-track {
  height: 100%;
}


 .example-enter {
  opacity: 0.01;
}

 .example-enter.example-enter-active {
  opacity: 1;
  transition: 250ms;
}

 .example-leave {
  opacity: 1;
}

 .example-leave.example-leave-active {
  opacity: 0.01;
  transition: 250ms;
}

 .align-middle {
  vertical-align: middle;
}

@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-moz-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-webkit-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@-o-keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}
@keyframes uil-ripple {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0 0 0 0;
  }
  33% {
    width: 44%;
    height: 44%;
    margin: -22% 0 0 -22%;
    opacity: 1;
  }
  100% {
    width: 88%;
    height: 88%;
    margin: -44% 0 0 -44%;
    opacity: 0;
  }
}

 .uil-ripple-css {
  background: none;
  position: relative;
  width: 200px;
  height: 200px;
}

 .uil-ripple-css div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  width: 0;
  height: 0;
  opacity: 0;
  border-radius: 50%;
  border-width: 12px;
  border-style: solid;
  -webkit-animation: uil-ripple 2s ease-out infinite;
  animation: uil-ripple 2s ease-out infinite;
}


 .uil-ripple-css div:nth-of-type(1) {
  border-color: #94a9ce;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
 .uil-ripple-css div:nth-of-type(2) {
  border-color: #667395;
}


 .hoverEnabled:hover { 
   background-color: steelblue !important
}


 input {
  padding:0px !important;
}

 .minWideButton{ 
   min-width: 260px; 
   margin-top:3px; 
   font-size: 120%;
   text-transform: capitalize !important;
   text-align: left !important;
   padding-left: 3px;
   padding-right: 3px;
}



@media all and (max-width: 600px )
{

   .HomeHeader {
    font-family: 'Capriola', sans-serif;
    font-size: 18pt
  }

   .mf_titleFont 
  {
    font-family: 'Tangerine', sans-serif;  
    font-size: calc(4vw + 4vh + 2vmin);
    font-size: 24pt
  }
  
  .marketingBlockText{
      font-family: 'Open Sans', cursive;
      font-size: 11pt;
      
      margin-right: auto;
      margin-left: auto;
      display: inline-block; 
      text-align: left
  }
}
@media all and (min-width: 601px)  and (max-width: 1200px) {

   .HomeHeader {
    font-family: 'Capriola', sans-serif;
    font-size: 18pt;
  }

   .mf_titleFont 
  {
    font-family: 'Tangerine', sans-serif;  
    font-size: 36pt;
    margin-top: 15px;
    line-height: 1;
  }
  .marketingBlockText{
      font-family: 'Open Sans', cursive;
      font-size: 12pt;
      
      margin-right: auto;
      margin-left: auto;
      display: inline-block; 
      text-align: left
  }
}

@media all and (min-width: 1200px) {

   .HomeHeader {
    font-family: 'Capriola', sans-serif;
    font-size: 24pt
  }

   .mf_titleFont 
  {
    font-family: 'Tangerine', sans-serif;  
    font-size: 48pt
  }
  .marketingBlockText{
      font-family: 'Open Sans', cursive;
      font-size: 12pt;
      margin-right: auto;
      margin-left: auto;
      display: inline-block; 
      text-align: left
  }
}

 .pageTitle 
{
  font-family: 'Tangerine', cursive;  
  font-size: 48pt;
  margin-left:15px;
  margin-top:10px;
  line-height: 1;
}


 .mf_copyFont 
{
  color:black;
  font-family: 'Tangerine', cursive;  
  font-size: calc(2vw + 2vh + 1vmin);
  margin-top: 15px;
}


 .marketingBlue {
  background-color: steelblue;
  color: white;
  min-height: 250px;
  padding:15px;
  position:relative;
  vertical-align: top;
  margin:0px !important;
}
 .marketingWhite {
  background-color: seashell;
  color: black;
  min-height: 250px;
  padding:15px;
  position:relative;
  margin:0px !important;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

 .editBoxNumber {
  display: inline-block;
  margin: 0px !important;
  padding: 2px !important;
  border: 1px solid silver  !important;
  border-collapse: 1px solid grey  !important;
  white-space:nowrap  !important;
  height:initial !important;
  width: 145px  !important;
  min-width: 145px  !important;   
  max-width: 145px  !important;   
  text-align: right;
}

 .editBoxNumberMini {
  display: inline-block;
  margin: 0px !important;
  padding: 2px !important;
  border: 1px solid silver  !important;
  border-collapse: 1px solid grey  !important;
  white-space:nowrap  !important;
  text-align: right;
  height:initial !important;
  width: 75px  !important;
  min-width: 75px  !important;   
  max-width: 75px  !important;   
}

 .editBoxNumberTiny {
  display: inline-block;
  margin: 0px !important;
  padding: 2px !important;
  border: 1px solid silver  !important;
  border-collapse: 1px solid grey  !important;
  white-space:nowrap  !important;
  text-align: right;
  height:initial !important;
  width: 45px  !important;
  min-width: 45px  !important;   
  max-width: 45px  !important;   
}

 .editFriendly {
  background-color: tan;
}

 .editBoxNumberCount {
  position:absolute;
  height: initial !important;
  top:0px;
  left:36%;
  width:15%;
  max-width:15%;
  
  display: inline-block;
  margin: 0px !important;
  padding: 2px !important;
  border: 1px solid silver  !important;
  border-collapse: 1px solid grey  !important;
  white-space:nowrap  !important;
  height: 19px;
  text-align: right;
}

 .editBoxNumberCost {
  position:absolute;
  height: initial !important;
  top:0px;
  left:60%;
  width:15%;
  max-width:15%;
  
  display: inline-block;
  margin: 0px !important;
  padding: 2px !important;
  border: 1px solid silver  !important;
  border-collapse: 1px solid grey  !important;
  white-space:nowrap  !important;
  height: 19px;
  text-align: right;
}



 .datepicker__tether-element {
  z-index: 2147483647;
}
 .datepicker__month-container {
  z-index: 2147483647;
}
 .liSpacerOnHome {
  padding-bottom:5px;
  vertical-align:'middle'
}

 .textSizeGeneral {
  font-size: 2vw;
}

.MuiFormHelperText-marginDense{
  margin-top:0px !important;
  max-height: 14px !important;
}

 .scrollWholePage {
  position: fixed;
  overflow: auto;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

.NormalInput input{
  margin-bottom: 0px !important; 
  height: 33px !important; 
  border-bottom: 0px !important; 
  padding-left: 13px !important; 
}


 .WholePage {
  position: absolute;
  overflow: hidden;
  overflow-y: hidden;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

 .FooterHolder {
  position:absolute; 
  bottom: 0px; 
  left:0px; 
  right:0px; 
  font-color: black !important;
  height:25px !important;
  line-height: 25px !important;
  background-color: navajowhite;
}

 .arrowSource {
}

 .arrowTarget {
  outline: 5px dashed #2196F3;
  transition-duration: 0.3s;
}


.VirtList {
  border: 1px solid grey;
}

.VirtListItemOdd {
  background-color: white;
  padding:2px;
}

.VirtListItemEven {
  background-color: #f1f1f1;
  padding:2px;
}